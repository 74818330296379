import { getRandom, getRangeValue } from "@tsparticles/engine";
import { Rotate } from "./Options/Classes/Rotate";
function updateAngle(particle, delta) {
    var _a, _b;
    const rotate = particle.rotate, rotateOptions = particle.options.rotate;
    if (!rotate || !rotateOptions) {
        return;
    }
    const rotateAnimation = rotateOptions.animation, speed = ((_a = rotate.velocity) !== null && _a !== void 0 ? _a : 0) * delta.factor, max = 2 * Math.PI, decay = (_b = rotate.decay) !== null && _b !== void 0 ? _b : 1;
    if (!rotateAnimation.enable) {
        return;
    }
    switch (rotate.status) {
        case "increasing":
            rotate.value += speed;
            if (rotate.value > max) {
                rotate.value -= max;
            }
            break;
        case "decreasing":
        default:
            rotate.value -= speed;
            if (rotate.value < 0) {
                rotate.value += max;
            }
            break;
    }
    if (rotate.velocity && decay !== 1) {
        rotate.velocity *= decay;
    }
}
export class RotateUpdater {
    constructor(container) {
        this.container = container;
    }
    init(particle) {
        const rotateOptions = particle.options.rotate;
        if (!rotateOptions) {
            return;
        }
        particle.rotate = {
            enable: rotateOptions.animation.enable,
            value: (getRangeValue(rotateOptions.value) * Math.PI) / 180,
        };
        particle.pathRotation = rotateOptions.path;
        let rotateDirection = rotateOptions.direction;
        if (rotateDirection === "random") {
            const index = Math.floor(getRandom() * 2);
            rotateDirection = index > 0 ? "counter-clockwise" : "clockwise";
        }
        switch (rotateDirection) {
            case "counter-clockwise":
            case "counterClockwise":
                particle.rotate.status = "decreasing";
                break;
            case "clockwise":
                particle.rotate.status = "increasing";
                break;
        }
        const rotateAnimation = rotateOptions.animation;
        if (rotateAnimation.enable) {
            particle.rotate.decay = 1 - getRangeValue(rotateAnimation.decay);
            particle.rotate.velocity =
                (getRangeValue(rotateAnimation.speed) / 360) * this.container.retina.reduceFactor;
            if (!rotateAnimation.sync) {
                particle.rotate.velocity *= getRandom();
            }
        }
        particle.rotation = particle.rotate.value;
    }
    isEnabled(particle) {
        const rotate = particle.options.rotate;
        if (!rotate) {
            return false;
        }
        return !particle.destroyed && !particle.spawning && rotate.animation.enable && !rotate.path;
    }
    loadOptions(options, ...sources) {
        if (!options.rotate) {
            options.rotate = new Rotate();
        }
        for (const source of sources) {
            options.rotate.load(source === null || source === void 0 ? void 0 : source.rotate);
        }
    }
    update(particle, delta) {
        var _a, _b;
        if (!this.isEnabled(particle)) {
            return;
        }
        updateAngle(particle, delta);
        particle.rotation = (_b = (_a = particle.rotate) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0;
    }
}
