{
    "autoPlay": true,
    "detectRetina": true,
    "duration": 0,
    "fpsLimit": 60,
    "fullScreen": {
        "enable": false,
        "zIndex": 0
    },
    "particles": {
        "color": {
            "value": "#ffffff"
        },
        "move": {
            "angle": {
                "offset": 0,
                "value": 0
            },
            "direction": "top",
            "drift": 0,
            "enable": true,
            "random": true,
            "size": true,
            "speed": 8
        },
        "number": {
            "density": {
                "enable": true,
                "area": 800,
                "factor": 1000
            },
            "limit": 0,
            "value": 80
        },
        "opacity": {
            "random": {
                "enable": true,
                "minimumValue": 0.1
            },
            "value": {
                "min": 0,
                "max": 0.5
            },
            "animation": {
                "count": 0,
                "enable": true,
                "speed": 0.25,
                "sync": false,
                "startValue": "min",
                "minimumValue": 0.1
            }
        },
        "size": {
            "random": {
                "enable": true,
                "minimumValue": 1
            },
            "value": {
                "min": 1,
                "max": 5
            }
        }
    },
    "pauseOnBlur": true,
    "pauseOnOutsideViewport": true,
    "responsive": [],
    "themes": [],
    "zLayers": 100
}
